import React from "react";

export default function Skill(props) {
  const { name, perc } = props;

  return (
    <div className="skill-lt">
      <h6>{name}</h6>
      <div className="skill-bar">
        <div className="skill-bar-in theme-bg" style={{width: perc+'%'}}>
          <span>{perc}%</span>
        </div>
      </div>
    </div>
  );
}
