import React, {useImperativeHandle, useState} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";

function BootstrapModal(props,ref) {

  const {header, tech, projectInfo, projectDetails, images} = props;  
  const [isVisible,setIsVisible] = useState(false);
  //const imageUrl = "aa";
  //console.log("image Url", imageUrl);

  const alterModal=()=>{
    setIsVisible(!isVisible);
  }

  useImperativeHandle(ref, ()=>({
    showhideModal() {
      alterModal();
    }
  }));

  return (
    <Modal size="xl" show={isVisible} onEscapeKeyDown={alterModal}>
      <ModalHeader className="font-weight-600">
        {header}
        <button
          class="btn-close px-btn px-btn-theme round-close btn-line-height"
          onClick={alterModal}
        >
          ×
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="bootstrap-modal-item flex-column-conditional">
          <div>
            <img src={'./images/projects/'+images[0]} alt={header} style={{ width: "100%" }} />
          </div>
          <div className=" padding-left-24 bootstrap-modal-item-text">
            <h4 className="text-6 font-weight-600"> Project Info: </h4>
            <p>{projectInfo}</p>
            <h4 className="text-6 font-weight-600"> Project Details: </h4>
            <ul className="list-style-1">
                <li>
                  <span> Technologies: </span>
                  {tech}
                </li>
              {projectDetails.map((projectDetailsObject) => {
                  var name = Object.getOwnPropertyNames(projectDetailsObject);
                  var value = projectDetailsObject[name];
                  return(
                    <li>
                      <span> {name}: </span>
                      {value}
                    </li>
                  );
              })}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className=" px-btn px-btn-theme" onClick={alterModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default React.forwardRef(BootstrapModal);