import React from "react";
import Skill from "./children/Skill";
import SkillPanel from "./children/SkillPanel";
import SkillPanelElement from "./children/SkillPanelElement";

export default function Skills() {
  return (
    <section className="section skill-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 my-3">
            <div className="sm-title">
              <h4 className="font-alt">Education</h4>
            </div>
            <ul className="aducation-box theme-bg">
              <li>
                <span className="dark-bg">2010-2012</span>
                <h6>Front-end Engineer</h6>
                <p>International Design Institute</p>
              </li>
              <li>
                <span className="dark-bg">2010-2012</span>
                <h6>Graphic Designer</h6>
                <p>Rio Design Institute</p>
              </li>
              <li>
                <span className="dark-bg">2010-2012</span>
                <h6>Visual Designer</h6>
                <p>Jakarta Design Institute</p>
              </li>
            </ul>
          </div> */}

          <SkillPanel aosAnimation="fade-in" aosDelay="100" aosDuration="500" themeBackground="theme-bg" panelTitle="Experience">
          <SkillPanelElement
              spanBackground="dark-bg"
              years="2016-present"
              title="Software Development Manager"
              company="Habib Bank Limited (HBL)"
            ></SkillPanelElement>
            <SkillPanelElement
              spanBackground="dark-bg"
              years="2014-2016"
              title="Senior Software Developer"
              company="THK Solutions"
            ></SkillPanelElement>
            <SkillPanelElement
              spanBackground="dark-bg"
              years="2013-2014"
              title="Software Developer"
              company="Lemon Commmunications"
            ></SkillPanelElement>
          </SkillPanel>

          <SkillPanel aosAnimation="fade-in" aosDelay="100" aosDuration="500" themeBackground="dark-bg" panelTitle="Education">
          <SkillPanelElement
              spanBackground="theme-bg"
              years="2012"
              title="Microsoft Certified © Technology Specialist"
              company="Microsoft Cetification"
            ></SkillPanelElement>
            <SkillPanelElement
              spanBackground="theme-bg"
              years="2012"
              title="Microsoft Certified © Professional"
              company="Microsoft Cetification"
            ></SkillPanelElement>
            <SkillPanelElement
              spanBackground="theme-bg"
              years="2009-2012"
              title="Software Engineer"
              company="Sir Syed University of Engineering and Technology"
            ></SkillPanelElement>
          </SkillPanel>

          <div data-aos="fade-in" data-aos-delay="100" data-aos-duration="500" className="col-lg-4 ml-auto my-3">
            <div className="sm-title">
              <h4 className="font-alt">My Skills</h4>
            </div>
            <div className="gray-bg skill-box">
              <Skill name="HTML5, CSS3, Javascript" perc="95"></Skill>
              <Skill name="React Js" perc="75"></Skill>
              <Skill name="ASP.NET and MVC 5" perc="90"></Skill>
              <Skill name="Angular" perc="70"></Skill>
              <Skill name="SQL Server" perc="85"></Skill>
              <Skill name="Sharepoint" perc="80"></Skill>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
