import React, { forwardRef } from "react";
import Portfolio from "../Portfolio";

function PortfolioItem(props) {
    const {title,technology,link,image,elementId,aosAnimation,aosDelay,aosDuration,portfolioClicked} = props;

  return (
    <>
      <div data-aos={aosAnimation} data-aos-duration={aosDuration} data-aos-delay={aosDelay} class="col-sm-6 col-lg-4 grid-item product">
        <div class="portfolio-box-01">
          <div class="portfolio-img">
            <div class="portfolio-info">
              <h5>
                <a
                  href={link}
                  onClick={portfolioClicked}
                  target="_blank"
                >
                  {title}
                </a>
              </h5>
              <span>{technology}</span>
            </div>
            <a href={link} class="gallery-link" onClick={portfolioClicked}>
              <img
                src={"./images/projects/"+image}
                alt={title}
                srl_elementid={elementId}
              />
            </a>
            <a
              href={link}
              class="portfolio-icon"
              target="_blank"
              rel="noopener noreferrer"
              onClick={portfolioClicked}
            >
              <span class="ti-link"></span>
            </a>
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
}

export default React.forwardRef(PortfolioItem);