import React from "react";
import SkillPanelElement from "./SkillPanelElement";

export default function SkillPanel(props) {
    const {themeBackground, panelTitle,aosAnimation,aosDelay,aosDuration} = props;
  return (

    <div data-aos={aosAnimation} data-aos-duration={aosDuration} data-aos-delay={aosDelay} className="col-lg-4 my-3">      
      <div className="sm-title">
        <h4 className="font-alt">{panelTitle}</h4>
      </div>
      <ul className={`aducation-box ${themeBackground}`}>
        {props.children}
      </ul>
    </div>
  );
}
