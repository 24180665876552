import React, { useRef, useState } from "react";
import axios from "axios";
import {CircleLoader} from "react-spinners";

export default function Contact() {

    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [subject,setSubject] = useState('');
    const [comment,setComment] = useState('');
    const [isSpinCircle,setIsSpinCircle] = useState(false);

    //var loader = document.getElementById("a");
 
    
    const submitForm = (e) => {
      setIsSpinCircle(true);

      //spinnerCircle.current.className = '';
        e.preventDefault();
        //console.log(name,email,subject,comment);
        //axios.post("https://localhost:44398/api/post",{ Name:name, Email:email,Subject:subject,Message:comment })
        axios.post("https://server.atif-uz-zaman.com/api/post",{ Name:name, Email:email,Subject:subject,Message:comment })
        .then(response=>{
          setIsSpinCircle(false);
          setName('');setEmail('');setSubject('');setComment('');

          //spinnerCircle.current.className = 'hidden';
          console.log(response);
        }).catch((ex)=>{
          setIsSpinCircle(false);
          console.log(ex);          
        });

    }

  return (
    <section id="contact" className="section after-left-section">
      <div className="container">
        <div class="row align-items-center">
          <div class="col-lg-6 my-3">
            <div class="contct-form">
              <div class="sm-title">
                <h4 class="font-alt">Contact us</h4>
              </div>
              <form class="contct-form" onSubmit={submitForm}>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Full name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label class="form-label">Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label class="form-label">Email</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Subject"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <label class="form-label">Subject</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <textarea
                        rows="4"
                        class="form-control"
                        placeholder="Type comment"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <label class="form-label">Comment</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="btn-bar">
                      <button class="px-btn px-btn-theme">
                        Send your message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4 ml-auto my-3">
            <div class="contact-info">
              <div class="sm-title">
                <h4 class="font-alt">Get in touch</h4>
                <p>
                  I am interested in freelance opportunities, specially large
                  projects. However, if you have other requests you can contact
                  me on this form.
                  {/* Always available for freelancing if the right project comes
                  along, Feel free to contact me. */}
                </p>
              </div>
              <div class="media">
                <div class="icon">
                  <i class="ti-map"></i>
                </div>
                <span class="media-body">
                  {
                    // TODO Atif
                    /* 123 Stree New York City , <br />
                  United States Of America <br />
                  NY 750065. */
                  }
                  Address will be available soon
                </span>
              </div>
              <div class="media">
                <div class="icon">
                  <i class="ti-email"></i>
                </div>
                <span class="media-body">contact@atif-uz-zaman.com</span>
              </div>
              <div class="media">
                <div class="icon">
                  <i class="ti-mobile"></i>
                </div>
                <span class="media-body">+92 331 2129852</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${ isSpinCircle ? "" : "hidden" } spinner`}>
        <CircleLoader color="#dc965a" loading={true} size={72} speedMultiplier={2} />
      </div>
    </section>
  );
}
