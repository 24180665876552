import React from "react";

export default function Services() {
  return (
    <section id="services" className="section services-section gray-bg">
      <div className="container">
        <div className="row justify-content-center section-title text-center">
          <div className="col-lg-7">
            <h3 className="font-alt">What I offer?</h3>
            <p>
              I design and develop services for customers of all sizes, specializing in creating 
              secure, styleish, modern websites and web services.
            </p>
          </div>
        </div>
        <div className="row">
          <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="500" className="col-md-6 col-lg-4 my-3">
            <div className="feature-box-03">
              <div className="icon">
                <i className="ti-ruler-pencil"></i>
              </div>
              <div className="feature-content">
                <h5>Sleek Design</h5>
                <p>
                  When it comes to business, first impressions matter, and good website design is the key to capitalizing on them.
                  I am not a typical designer, but I do possess visual abilities which makes me an excelent presentor. 
                  {/* will design a sleek web interface for your application. */}
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="500" className="col-md-6 col-lg-4 my-3">
            <div className="feature-box-03">
              <div className="icon">
                <i className="ti-image"></i>
              </div>
              <div className="feature-content">
                <h5>Functionality</h5>
                <p>
                An excellent site is not judged solely on its looks, but on its functionality and usability as well. 
                My experience as a programmer allows me to come up with intelligent solutions to technical challenges.
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="500" className="col-md-6 col-lg-4 my-3">
            <div className="feature-box-03">
              <div className="icon">
                <i className="ti-layout"></i>
              </div>
              <div className="feature-content">
                <h5>Clean Code</h5>
                <p>
                A programmer who writes clean code is an artist who can take a blank screen through a series of 
                transformations until it is an elegantly coded system. I write clean, understandable and maintainable code
                for your application.                   
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
