import React, { useRef, useState } from "react";
import image from '../static/images/projects/services2.jpg'
import PortfolioItem from "./children/PortfolioItem";
import portfolioItems from '../project.json'
import BootstrapModal from "./children/BootstrapModal";

export default function Portfolio() {

  const myRefs = useRef([]);

  // var i=0;
  // for(var i=0;i< portfolioItems.projects.length;i++){
  //   var item =  portfolioItems.projects[i];
  // }

  return (
    <section id="work" class="section gray-bg">
      <div class="container">
        <div class="row justify-content-center section-title text-center">
          <div class="col-lg-7">
            <h3 class="font-alt">My Portfolio</h3>
            <p>
              I made large scale of secure and stylish applications for banks, domestic clients and international clients.
              In my 10 years of journey, I design specially on ASP.NET, Sharepoint, SQL Server
              and now I am developing on React, Angular  and node as well. 
              <br/>
              Here are my details...

              {/* I design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and online stores. */}
            </p>
          </div>
        </div>
        <div class="positon-relative">
          <div class="portfolio-filter-01">
            <div class="react-tabs" data-tabs="true">
              <div>
                <div
                  class="react-tabs__tab-panel react-tabs__tab-panel--selected"
                  role="tabpanel"
                  id="react-tabs-1"
                  aria-labelledby="react-tabs-0"
                >
                  <span>
                  </span>
                  <div class="portfolio-content row lightbox-gallery">
                        {
                        portfolioItems.projects.map((item,index)=>{
  
                        //var bootstrapModal = useRef();
                        function onPortfolioClicked(e){
                          //item.reference.current.showhideModal();
                          //.current.showhideModal();
                          e.preventDefault();
                          console.log(myRefs);
                          myRefs.current[index].showhideModal();
                        }
  
                        return (
                          <PortfolioItem
                            portfolioClicked={onPortfolioClicked}
                            aosAnimation={item.aosAnimation}
                            aosDelay={item.aosDelay}
                            aosDuration={item.aosDuration}
                            title={item.header}
                            technology={item.Technologies}
                            link="#"
                            image={item.images[0]}
                            elementId={item.elementId}
                          >
                            <BootstrapModal
                              ref={el => (myRefs.current[index] = el)}
                              header={item.header}
                              projectInfo={item.projectInfo}
                              projectDetails={item.projectDetails}
                              images={item.images}
                              tech={item.Technologies}
                            ></BootstrapModal>
                          </PortfolioItem>
                        );
                      })}

                    {/* <PortfolioItem aosAnimation="fade-up" aosDelay="100" title="Roshan Digital Account" technology="SharePoint 2013" link="#" image={image} elementId="1"></PortfolioItem> */}

                    {/* <PortfolioItem aosAnimation="fade-up" aosDelay="300" title="Roshan Digital Account" technology="SharePoint 2013" link="#" image={image} elementId="1"></PortfolioItem>
                    <PortfolioItem aosAnimation="fade-right" aosDelay="300" title="Roshan Digital Account" technology="SharePoint 2013" link="#" image={image} elementId="1"></PortfolioItem>
                    <PortfolioItem aosAnimation="fade-up" aosDelay="300" title="Roshan Digital Account" technology="SharePoint 2013" link="#" image={image} elementId="1"></PortfolioItem>
                    <PortfolioItem aosAnimation="fade-up" aosDelay="500" title="Roshan Digital Account" technology="SharePoint 2013" link="#" image={image} elementId="1"></PortfolioItem>
 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
