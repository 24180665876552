import userEvent from "@testing-library/user-event";
import React, { useEffect, useRef, useState } from "react";
import logo from "../static/images/logo-light.svg"

function Header(props) {

  // var headerElement = useRef();
  // const [isIntersecting,setIntersecting] = useState(true);

  // var headerObserver = new IntersectionObserver(entries => {
  //   var header = entries[0];
  //   console.log("Ratio",header.intersectionRatio);    
  //   setIntersecting(header.isIntersecting);
  // })

  // useEffect(()=>{
  //   headerObserver.observe(headerElement.current);
  // },[])

  const [isActive,setIsActive] = useState(false);

  const buttonClicked = ()  => {
    setIsActive(!isActive);
  }
  


  
  return (
    // <header ref={headerElement} className={`${'main-header'} ${!isIntersecting ? 'fixed-header' : ''}`}>
    <header className={props.className}>
      <nav className="container">
        <div className=" header-transparent">
          <a aria-current="page" className="active" href="/">
            {/* <img
              className="navbar-brand"
              src={logo}
              alt="brand logo"
            /> */}
            <span class="logo">
              Atif
            </span>
          </a>
          <button className="navbar-toggler" type="button" onClick={buttonClicked}>
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div className={` ${isActive ? 'active' : ''} mobile-menu navbar-collapse justify-content-end`}>
            <a className="sidebar-logo" href="/">
              {/* <img src="img/logo/logo-light.svg" alt="brand" /> */}
              <span class="logo">
                Atif
              </span>
            </a>
            <ul className="anchor_nav navbar-nav ml-auto">
              <li className="current">
                <a className="nav-link" href="#home">
                  <span>Home</span>
                </a>
              </li>
              <li className="">
                <a className="nav-link" href="#about">
                  <span>About Us</span>
                </a>
              </li>
              <li className="">
                <a className="nav-link" href="#services">
                  <span>Services</span>
                </a>
              </li>
              <li className="">
                <a className="nav-link" href="#work">
                  <span>Portfolio</span>
                </a>
              </li>
              {/* <li className="">
                <a className="nav-link" href="#blog">
                  <span>Blog</span>
                </a>
              </li> */}
              <li className="">
                <a className="nav-link" href="#contact">
                  <span>Contact</span>
                </a>
              </li>
            </ul>
            <button className=" sidebar-bottom-closer" onClick={buttonClicked} type="button">
              Close Sidebar
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;