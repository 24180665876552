import logo from './logo.svg';
import React,{useEffect,useRef,useState} from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import '../node_modules/aos/dist/aos.css'

import Header from './components/Header';
import Intro from './components/Intro';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import config from './config.json';

function App() {
  

  console.log("node env", process.env.NODE_ENV);

  if(!window.location.href.startsWith("https") && process.env.NODE_ENV == "production")
  {
    window.location.href = config.websiteUrl;
  }

  var introElement = useRef();
  const [isIntersecting,setIntersecting] = useState(true);

  var introObserver = new IntersectionObserver(entries => {
    var intro = entries[0];
    console.log("Ratio",intro.intersectionRatio);    
    setIntersecting(!intro.isIntersecting);
  })

  useEffect(()=>{
    console.log("introElement",introElement);
    introObserver.observe(introElement.current);
  },[])
  
  return (
    <div className="App">
      <Header className={`main-header ${isIntersecting ? 'fixed-header' : ''}`}></Header>
      <Intro  ref={introElement}></Intro>
      <AboutUs></AboutUs>
      <Portfolio></Portfolio>
      <Skills></Skills>
      <Services></Services>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
