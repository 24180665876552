import React from "react";
import cv from '../static/documents/atif-uz-zaman.pdf'
import aboutPicture from '../static/images/about-picture.jpg'
function AboutUs() {
  return (
    <section id="about" className="section about-section ">
      <div className="container">
        <div className="row align-items-center justify-content-center services-inner-section">
          <div className="col-lg-5">
            <div>
              <div className="row g-2 about-gallery">
                <div className="col-12">
                  <a href="img/about/about-4.jpg">
                    <img
                      src={aboutPicture}
                      alt="Studio"
                      className="round-15"
                      srl_elementid="0"
                    />
                  </a>
                </div>
                {/* <div className="col-6">
                  <a href="img/about/about-2.jpg">
                    <img
                      src="img/about/about-2.jpg"
                      alt="Dark Studio"
                      className="rounded-3"
                      srl_elementid="1"
                    />
                  </a>
                </div>
                <div className="col-6">
                  <a href="img/about/about-6.jpg">
                    <img
                      src="img/about/about-6.jpg"
                      alt="Dark Fashion"
                      className="rounded-3"
                      srl_elementid="2"
                    />
                  </a>
                </div>
                <div className="col-6">
                  <a href="img/about/about-4.jpg">
                    <img
                      src="img/about/about-4.jpg"
                      alt="Light Relay"
                      className="rounded-3"
                      srl_elementid="3"
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-7 ps-xl-5">
            <div className="typo-box about-me">
              <h3>Atif uz Zaman Khan</h3>
              <h5>
                A Lead <span className="color-theme"> Developer </span> and <span className="color-theme">UX &amp; UI</span> designer
                {/* based in <span className="color-theme">Canada</span> */}
              </h5>
              <p>
                I design and develop services for corporate and freelancers,
                specializing in creating secure, styleish, modern websites and web services.
              </p>
              <div className="py-3">
                {/* <img
                  className="author-signeture"
                  src="img/signature.png"
                  alt="author signature"
                /> */}
              </div>
              <div className="row about-list">
                <div className="col-md-6">
                  <div className="media">
                    <label>Birthday</label>
                    <p>19th July 1989</p>
                  </div>
                  <div className="media">
                    <label>Age</label>
                    <p>{  new Date().getFullYear() - new Date(1989,7,19).getFullYear()} Yr</p>
                  </div>
                  {/* <div className="media">
                    <label>Residence</label>
                    <p>Canada</p>
                  </div> */}
                  <div className="media">
                    <label>Address</label>
                    <p>Will be available soon</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="media">
                    <label>E-mail</label>
                    <p>contact@atif-uz-zaman.com</p>
                  </div>
                  <div className="media">
                    <label>Phone</label>
                    <p>+92 331 2129852</p>
                  </div>
                  <div className="media">
                    <label>Skype</label>
                    <p>atifuk1</p>
                  </div>
                  <div className="media">
                    <label>Freelance</label>
                    <p>Available</p>
                  </div>
                </div>
              </div>
              <div className="btn-bar">
                <a
                  className="px-btn px-btn-theme"
                  href={cv}
                  download=""
                >
                  Download CV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;