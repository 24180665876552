import React from "react";

export default function SkillPanelElement(props) {
  const { spanBackground, years, title, company } = props;
  return (
    <li>
      <span className={`${spanBackground}`}>{years}</span>
      <h6>{title}</h6>
      <p>{company}</p>
    </li>
  );
}
